import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import WebFont from 'webfontloader';

import App from './client/App/App';

import registerServiceWorker from './client/registerServiceWorker';

console.log('loading fonts');
WebFont.load({
  google: {
    families: ['Archivo Narrow:400,500,600,700', 'Open Sans:100,200,300,400,700']
  }
})

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
