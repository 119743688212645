import React, { Component } from "react";

import jamesProfile from "./img/JamesPike.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPhone, faAt, faIdCard } from "@fortawesome/pro-regular-svg-icons";

export default class JamesContact extends Component {

  componentDidMount(){
    document.body.style.height = '100vh'
  }

  render(){
    return (
      <div
        style={{
          marginTop: 80,
          minHeight: 'calc( 100vh - 175px)',
        }}
        className="p-3 d-flex align-items-center justify-content-center flex-column text-white">
          <div
            style={{
              width: 200,
              height: 200,
              backgroundImage: `url(${jamesProfile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              borderRadius: 100,
            }}
            className="m-3"
            ></div>
          <h1>James Pike</h1>
          <h6>Software Developer</h6>
          <div className="btn-group-vertical flex-wrap justify-content-center ">
            <a  href="tel:4036909283" className="btn btn-primary">
              <FontAwesomeIcon className="mr-1" icon={ faPhone }/> 403 690 9283
            </a>
            <a  href="mailto:james.pike@agilecollab.ca" className="btn btn-primary">
              <FontAwesomeIcon className="mr-1" icon={ faAt }/> james.pike@agilecollab.ca
            </a>
            <a  href="/vcards/James_Pike.vcf" className="btn btn-primary">
              <FontAwesomeIcon className="mr-1" icon={ faIdCard }/> Download Contact
            </a>
          </div>
    </div>)}
}
