import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ParallaxProvider } from 'react-scroll-parallax';

import './App.scss';
//import "animate.css";


import MainNav from "../MainNav/MainNav";
import Footer from "../Footer/Footer";

import Home from "../Home/Home2";

import JamesContact from "../Contact/JamesContact";
import JeffContact from "../Contact/JeffContact";
//Removed, For now we have gone to SPA layout
//import Projects from "../Projects/Projects";


import NotFound from "../NotFound/NotFound";

class App extends Component {

  constructor(props){
    super(props);

    this.state = {
      beforeScroll: true
    }
  }

  componentDidMount(){
    window.addEventListener("scroll", this.firstScroll);
  }

  firstScroll = () => {
    this.setState({ beforeScroll: false });
    window.removeEventListener("scroll", this.firstScroll);
  }


  render() {
    const { beforeScroll } = this.state;

    return (
      <ParallaxProvider>
      <Router>

        <Route
          render={ ({location}) => (
            <div className="App">
              <header className="App-header">
                <Link to="/" className={`logoLink ${ beforeScroll ? 'beforeScroll' : ''}`}><span className="logo">agile<span className="orange">collab</span></span></Link>
                <MainNav />
              </header>
              <TransitionGroup>
                <CSSTransition key={ location.key } classNames="fade" timeout={ 300 }>
                  <Switch location={ location }>
                    <Route exact path="/" component={Home} />
                    {/*<Route path="/projects" component={Projects} />*/}
                    <Route exact path="/james" component={ JamesContact } />
                    <Route exact path="/jeff" component={ JeffContact } />
                    <Route component={ NotFound } />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
              <footer>
                <Footer />
              </footer>
            </div>
          )} />

      </Router>
      </ParallaxProvider>
    );
  }
}

export default App;
