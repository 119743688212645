import React, { Component } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Parallax } from "react-scroll-parallax";

import "./Home2.scss";

import space_background from "./img/space_background.jpg";

//Hero
import hero_tagline_svg from "./img/hero_tagline.svg";
import hero_tagline_png from "./img/hero_tagline.png";

//Contact
import astronautPng from "./img/astronaut_isolated.png";
import astronautAstroidPng from "./img/astronaut_astroid_isolated.png";

//Expo
import compImage from "./img/iMacLayer.png";
import phoneImage from "./img/iPhoneLayer.png";
import mugImage from "./img/mugLayer.png";

import mars_full from "./img/mars_full_sm.png";

import zoonWebsiteImage from "./img/zoonWebsiteFull.png";
import zoonMobileImage from "./img/zoonMobileFull.png";

import smoke1Layer from "./img/smoke1Layer.png";
import smoke2Layer from "./img/smoke2Layer.png";
import smoke3Layer from "./img/smoke3Layer.png";
import smoke4Layer from "./img/smoke4Layer.png";

//Client
import captivaLogoSVG from "./img/Captiva_Logo.svg";
import qwixskinzLogoSVG from "./img/Qwixskinz_Logo.svg";
import qwixskinzLogoPNG from "./img/Qwixskinz_Logo.png";
import upkeepLogoSVG from "./img/Upkeep_Logo.svg";
import upkeepLogoPNG from "./img/Upkeep_Logo.png";
import zoonLogoSVG from "./img/Zoon_Logo.svg";
import zoonLogoPNG from "./img/Zoon_Logo.png";
import oglLogoSVG from "./img/OGL_Logo.svg";
import oglLogoPNG from "./img/OGL_Logo.png";
import weMapleSVG from "./img/WeMaple_Logo.svg";
import weMaplePNG from "./img/WeMaple_Logo.png";

//Work Expo
import zoonPortalScreen from "./img/zoonPortalScreen.jpg";
import zoonPortalMobileScreen from "./img/zoonPortalMobileScreen.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAstronaut as faUserAstronautLight
} from "@fortawesome/pro-light-svg-icons";

import {
  faUserAstronaut as faUserAstronautPro,
  faSpinnerThird as faSpinnerThirdPro
} from "@fortawesome/pro-regular-svg-icons";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expoVisible: false,
      homeVisible: false,
      workExpoVisible: false,
      frame: 0,
      progress: 0,
      duration: 30,
      itemWidth: 0, //window.innerWidth / 8,
      itemSpace: 0, //window.innerWidth / 3,
      logos: [
        { svg: captivaLogoSVG, alt: "Captiva Sciences Inc." },
        { svg: weMapleSVG, png: weMaplePNG, alt: "WeMaple" },
        { svg: oglLogoSVG, png: oglLogoPNG, alt: "OGL Egineering Inc." },
        { svg: zoonLogoSVG, png: zoonLogoPNG, alt: "Zoon Media Inc." },
        { svg: upkeepLogoSVG, png: upkeepLogoPNG, alt: "Upkeep Group Inc." },
        { svg: qwixskinzLogoSVG, png: qwixskinzLogoPNG, alt: "Qwixskinz Inc." }
      ],
      email: {
        firstName: "",
        lastName: "",
        jobTitle: "",
        company: "",
        phone: "",
        email: "",
        message: ""
      },
      valid: {
        firstName: true,
        lastName: true,
        jobTitle: true,
        company: true,
        phone: true,
        email: true,
        message: true
      },
      messages: [],
      formState: "ready"
    }



    this.blockCall = [];


  }

  componentDidMount() {
    this.runAnimation();
    this.onResize();
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }


  emailInputChange = e => {
    const { email } = this.state;
    const { target: { name, value }} = e;
    const update = {};
    update[name] = value;
    this.setState({ email: Object.assign({}, email, update)});
  }

  notEmpty = (s) => !!s

  isEmail = (s) => {
    const emailFormat = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return( emailFormat.test(s) )
  }

  isPhone = (s) => {
    const phoneFormat = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return( phoneFormat.test(s) )
  }


  expoVisible = isVisible => {
    this.setState({ expoVisible: isVisible });
  };

  homeVisible = isVisible => {
    this.setState({ homeVisible: isVisible });
  };

  workExpoVisible = isVisible => {
    this.setState({ workExpoVisible: isVisible });
  };

  onResize = () => {
    const { innerWidth } = window;
    //how many logos should fit on screen?
    const n = innerWidth > 600 ? 4 : 2;

    this.setState({
      itemWidth: window.innerWidth / 8,
      itemSpace: Math.min(window.innerWidth / n + window.innerWidth / 8, 1000)
    });
  };

  runAnimation = () => {
    const { frame, duration } = this.state;

    let newFrame = frame / 60 / duration <= 1 ? frame + 1 : 0;
    let newProgress = frame / 60 / duration;

    this.setState({ frame: newFrame, progress: newProgress });
    window.requestAnimationFrame(this.runAnimation);
  };

  horizontalScroll = item => {
    if (!this.client_scroller) return {}; //Need first render for ref
    const { logos, progress, itemWidth, itemSpace } = this.state;
    const { innerWidth } = window;

    const total = logos.length;
    const totalWidth = total * itemWidth + total * itemSpace;
    let left =
      (totalWidth - innerWidth + itemWidth + itemSpace) * progress +
      (item + 1) * itemSpace -
      innerWidth;
    //loopback
    if (left > totalWidth - innerWidth - itemWidth - itemSpace) {
      let np = progress - 1;
      left =
        (totalWidth - innerWidth + item) * np +
        (item + 1) * itemSpace -
        innerWidth;
    }

    return { left, opacity: 0.5 };
  };

  sendEmail = () => {
    const {email, valid} = this.state;

    const rules = {
      company: [
        { rule: this.notEmpty, message: "Company name must be provided."} ],
      email: [
        { rule: this.notEmpty, message: "Email field must be filled."},
        { rule: this.isEmail, message: "The email provided does not appear to be valid."}],
      phone: [
        { rule: this.notEmpty, message: "Phone number must be provided." },
        { rule: this.isPhone, message: "The phone number provided does not appear to be valid." }],
      firstName:[
        { rule: this.notEmpty, message:  "First name field must be filled."} ],
      lastName: [
        {rule: this.notEmpty, message: "Last name field must be filled."} ],
      jobTitle: [
        {rule: this.notEmpty, message: "Job title field must be filled."}],
      message: [
        { rule: this.notEmpty, message: "A message must be provided."} ]
    }

    let messages = [];
    Object.keys(rules).map( property => {
      rules[property].map(check => {
        if(!check.rule(email[property])) {
          messages.push(check.message);
          valid[property] = false;
        } else { valid[property] = true;  }
        });
      })


    if(messages.length > 0){
      this.setState({ messages, valid });
    } else {
      this.setState({ formState: "sending" })

      fetch('/api/email', {
        method: "POST",
        body: JSON.stringify(email),
        headers: {
          "content-type": "application/json"
        }
      })
      .then(resp => resp.json())
      .then(response => {
        console.log('response');
        this.setState({
          formState: "completed",
          messages: ["Thanks for your message, we will be in touch soon!"],
          email: { firstName: "", lastName: "", jobTitle: "", company: "", phone: "", message: "", email: "" }
        }, () => { setTimeout(()=>{ this.setState({ formState: "ready", messages: [] })}, 5000)});
      })
      .catch()
    }



  };

  render() {
    const { expoVisible, homeVisible, workExpoVisible, logos, valid, messages, formState, email: { firstName, lastName, jobTitle, company, email, phone, message } } = this.state;

    return (
      <section
        id="home-wrapper"
        style={{ backgroundImage: `url(${space_background})` }}
      >
        <section id="hero">
          <div className="hero-wrapper">
            <Parallax
              offsetYMax={200}
              offsetYMin={-300}
              slowerScrollRate
              tag="div"
            >
              <VisibilitySensor onChange={this.homeVisible}>
                <div
                  className={`homeContent ${
                    homeVisible ? "homeInView" : "homeOutView"
                  }`}
                >
                  <h5 className="text-uppercase">Let Us Help You</h5>
                  <object data={hero_tagline_svg} width="350">
                    <img
                      src={hero_tagline_png}
                      width="350"
                      alt="Let Us Help You Bring Your Vision To Life"
                    />
                  </object>
                </div>
              </VisibilitySensor>
            </Parallax>
          </div>
        </section>

          <section id="expo">
            <div
              className="expo-wrapper"
              style={{ backgroundImage: `url(${mars_full})` }}
            >
              <svg
                className={`arcText ${
                  !homeVisible ? "expoInView" : "expoOutView"
                }`}
                style={{ mixBlendMode: "multiply" }}
                viewBox="0 0 1920 350"
                width="100%"
              >
                <defs>
                  <path
                    id="marsArcPath"
                    style={{ fill: "rgba(0,0,0,0.3)", stroke: "#fff" }}
                    d="M162,434.3c0,0,251-434,798-434c548,0,798,434,798,434H162z"
                  />
                </defs>
                <defs>
                  <filter
                    id="filter"
                    x="-20%"
                    y="-20%"
                    width="140%"
                    height="140%"
                    filterUnits="objectBoundingBox"
                    primitiveUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feBlend
                      mode="color"
                      x="0%"
                      y="0%"
                      width="100%"
                      height="100%"
                      in="SourceGraphic"
                      in2="SourceGraphic"
                      result="blend1"
                    />
                  </filter>
                </defs>
                <defs>
                  <filter id="floodFilter" filterUnits="userSpaceOnUse">
                    <feFlood
                      x="50"
                      y="50"
                      width="100"
                      height="100"
                      floodColor="white"
                      floodOpacity="0.5"
                    />
                  </filter>
                </defs>
                <text
                  x="17%"
                  dy="44%"
                  style={{
                    fill: "#aaa",
                    opacity: 0.9,
                    textShadow: "0px 0px 2px #333",
                    fontFamily: "Archivo Narrow, sans-sarif",
                    fontSize: "10em",
                    filter: "url(#filter)"
                  }}
                >
                  <textPath
                    xlinkHref="#marsArcPath"
                    method="stretch"
                    side="right"
                  >
                    We Write Great Code
                  </textPath>
                </text>
              </svg>

              <div
                className="home-expo-wrapper"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <VisibilitySensor onChange={this.expoVisible}>
                  <div className="home-expo">
                    <Parallax
                      offsetYMax={110}
                      offsetYMin={-30}
                      slowerScrollRate
                      tag="div"
                    >
                      <div className="compWrapper">
                        <div className="content">
                          <Parallax
                            offsetYMax={39}
                            offsetYMin={-10}
                            slowerScrollRate={false}
                          >
                            <img
                              src={zoonWebsiteImage}
                              alt="Zoon Media Website"
                            />
                          </Parallax>
                        </div>
                        <img src={compImage} alt="" />
                      </div>
                    </Parallax>

                    <Parallax
                      offsetXMax={-10}
                      offsetXMin={3}
                      slowerScrollRate
                      tag="div"
                    >
                      <div className="phoneWrapper">
                        <div className="content">
                          <Parallax
                            offsetYMax={39}
                            offsetYMin={-10}
                            slowerScrollRate={false}
                          >
                            <img src={zoonMobileImage} alt="" />
                          </Parallax>
                        </div>

                        <img
                          src={phoneImage}
                          alt="Zoon Media Website on Mobile"
                        />
                      </div>
                    </Parallax>
                    <Parallax
                      offsetXMax={10}
                      offsetXMin={-3}
                      slowerScrollRate
                      tag="div"
                    >
                      <div className="smokeWrapper">
                        <img className="smoke1" src={smoke1Layer} alt="" />
                        <img className="smoke2" src={smoke2Layer} alt="" />
                        <img className="smoke3" src={smoke3Layer} alt="" />
                        <img className="smoke4" src={smoke4Layer} alt="" />
                      </div>
                    </Parallax>
                    <Parallax
                      offsetXMax={10}
                      offsetXMin={-3}
                      slowerScrollRate
                      tag="div"
                    >
                      <div className="mugWrapper">
                        <img src={mugImage} alt="" />
                      </div>
                    </Parallax>
                  </div>
                </VisibilitySensor>
              </div>

              <div className="">
                <div className="container wwd">
                  <div className="row text-white">
                    {/*<div className="col-6 col-md-3">*/}
                    <div className="col-12 col-sm-6 col-lg-3">
                      <h6 className="text-center text-uppercase ">
                        Agile
                        <br /> Development
                      </h6>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <h6 className="text-center text-uppercase ">
                        Collaborative
                        <br /> Process
                      </h6>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <h6 className="text-center text-uppercase ">
                        Web / Mobile / API
                        <br /> Development
                      </h6>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                      <h6 className="text-center text-uppercase ">
                        Technology
                        <br /> Integrations
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
  

        <section id="clients">
          <Parallax offsetYMax={15} offsetYMin={0} slowerScrollRate tag="div">
            <div className="clients-wrapper">
              <div className="client-content">
                <div className="container mt-3 mb-0">
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-3 p-3 mb-0">
                      <h4 className="text-white text-uppercase mb-3">
                        Custom Software Development
                      </h4>
                      <hr className="border-white" />
                      <p className="text-white">
                        Agilecollab is a Software development agency based out
                        of Calgary Alberta, Est 2015. We are experts at creating
                        web &amp; mobile applications, as well as websites and
                        company portals. Our mission is to produce custom
                        tailored solutions to help our clients optimize current
                        processes by utilizing the latest technologies.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="client-scroller"
                  ref={ref => (this.client_scroller = ref)}
                >
                  {logos.map((logo, index) => (
                    <div
                      className="client-item"
                      key={index}
                      style={this.horizontalScroll(index)}
                    >
                      <object data={logo.svg} alt={logo.alt}>
                        {logo.png ? (
                          <img src={logo.png} alt={logo.alt} />
                        ) : null}
                      </object>
                    </div>
                  ))}
                </div>
              </div>
              <Parallax
                offsetXMax={-2}
                offsetXMin={2}
                slowerScrollRate
                tag="div"
                className="astroid-wrapper"
              >
                <img
                  src={astronautAstroidPng}
                  alt="Agilecollab Astronaut Astroid"
                />
                <Parallax
                  offsetYMax={1}
                  offsetYMin={-5}
                  offsetXMax={1}
                  offsetXMin={-10}
                  tag="div"
                  className="astronaut-shadow"
                >
                  <div className="shadow" />
                </Parallax>
              </Parallax>
            </div>
          </Parallax>
        </section>

        <section id="work-hightlights">
          <div className="work-hightlights-wrapper">
            <div className="container-fluid">
              <div className="row wbwb">
                <div className="col-12 col-lg-6 offset-lg-3 p-3">
                  <h4 className="text-white text-uppercase">Zoon Media</h4>
                  <hr className="border-white" />
                  <p className="text-white">
                    Zoon Media is a professional realestate production company
                    producing amazing photographic, cinematic, and 3D
                    experiences for their clients.
                  </p>
                </div>
                <div className="col-12 col-lg-4 offset-lg-1">
                  <VisibilitySensor
                    onChange={this.workExpoVisible}
                    partialVisibility={true}
                  >
                    <div
                      className={`highlight-expo set-perspective animated delay-2s lightSpeedIn ${
                        workExpoVisible ? "inView" : "outView"
                      }`}
                    >
                      <div className="portal-screen skew-3d-1">
                        <img
                          className=""
                          src={zoonPortalScreen}
                          alt="Zoon Portal Dashboard"
                        />
                      </div>
                      <div className="portal-mobile-screen skew-3d-2">
                        <img
                          src={zoonPortalMobileScreen}
                          alt="Zoon Portal Login on Mobile"
                        />
                      </div>
                    </div>
                  </VisibilitySensor>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="card mb-3">
                    <div className="card-header">
                      <h4 className="card-title">Zoon Website</h4>

                      <p>
                        Like most companies Zoon's Brand image is very important
                        to them. Agilecollab designed and created Zoon Media's
                        website to best reflect their corporate identity and
                        existing branding.
                      </p>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-header">
                      <h4 className="card-title">Zoon Client Portal</h4>
                      <p>
                        Zoon produces hundreds of GB of digital assets every day
                        and initially needed a way to effeciently manage and
                        distribute these assets to their clients. The Zoon
                        Client Portal was built to centrally manage all of
                        Zoon's asset management needs and has since grown to
                        support: scheduling of staff, communication with their
                        clients, client support, and generates profit through
                        Zoon's custom feature page creator.
                      </p>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-header">
                      <h4 className="card-title">Zoon Cloud</h4>
                      <p>
                        The Zoon Cloud supports all of Zoons client
                        applications, ensuring stable operation of their
                        everyday operations. Agilecollab designed and
                        implemented a strategy that would allow Zoon to grow.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact">
          <div className="contact-wrapper">
            <div className="container text-left text-white contactArea">
              <div className="row">
                <div className="col-12 col-md-4">
                  <Parallax
                    offsetYMax={10}
                    offsetYMin={-50}
                    slowerScrollRate
                    tag="div"
                  >
                    <img src={astronautPng} alt="" />
                  </Parallax>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row">
                    <div className="col-12">
                      <h2>Get In Touch</h2>
                      <hr className="border-white" />
                      <p>
                        We would love to hear your idea's and see how we can
                        help you bring them to life.
                      </p>
                    </div>
                    <div className="col-6 ">
                      <div
                        className={`form-group ${
                          firstName ? "filled" : null
                        } ${ !valid['firstName'] ? "invalid" : ""}`}
                      >
                        <label htmlFor="fname">First Name</label>
                        <input
                          type="text"
                          id="fname"
                          className="form-control"
                          name="firstName"
                          onChange={ this.emailInputChange }
                          value={ firstName }

                        />
                        <small id="fnameHelp" className="form-text text-muted">
                          Enter your first name here.
                        </small>
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        className={`form-group ${
                          lastName ? "filled" : ""
                        } ${ !valid['lastName'] ? "invalid" : ""}`}
                      >
                        <label htmlFor="lname">Last Name</label>
                        <input
                          type="text"
                          id="lname"
                          className="form-control"
                          name="lastName"
                          onChange={ this.emailInputChange }
                          value={ lastName }

                        />
                        <small id="lnameHelp" className="form-text text-muted">
                          Enter your last name here.
                        </small>
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        className={`form-group ${
                          phone ? "filled" : ""
                        } ${ !valid['phone'] ? "invalid" : ""}`}
                      >
                        <label htmlFor="phone">Phone</label>
                        <input
                          type="text"
                          pattern="^(\+)?([0-9]+(\s|-)[0-9]+)+$"
                          id="phone"
                          className="form-control"
                          name="phone"
                          onChange={ this.emailInputChange }
                          value={ phone }
                          required
                        />
                        <small id="phoneHelp" className="form-text text-muted">
                          Enter Your 10-digit Phone Number
                        </small>
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        className={`form-group ${
                          email ? "filled" : ""
                        } ${ !valid['email'] ? "invalid" : ""}`}
                      >
                        <label htmlFor="email">Email Address</label>
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          name="email"
                          onChange={ this.emailInputChange }
                          value={ email }
                        />
                        <small id="emailHelp" className="form-text text-muted">
                          We'll never share your email with anyone else.
                        </small>
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        className={`form-group ${
                          jobTitle ? "filled" : ""
                        } ${ !valid['jobTitle'] ? "invalid" : ""}`}
                      >
                        <label htmlFor="jobTitle">Job Title</label>
                        <input
                          type="text"
                          className="form-control"
                          name="jobTitle"
                          id="jobTitle"
                          onChange={ this.emailInputChange }
                          value={ jobTitle }

                        />
                        <small id="jobTHelper" className="form-text text-muted">
                          What's your title?
                        </small>
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        className={`form-group ${
                          company ? "filled" : null
                        } ${ !valid['company'] ? "invalid" : ""}`}
                      >
                        <label htmlFor="companyName">Company</label>
                        <input
                          type="text"
                          className="form-control"
                          name="company"
                          id="company"
                          value={ company }
                          onChange={ this.emailInputChange }
                        />
                        <small
                          id="comapnyHelper"
                          className="form-text text-muted"
                        >
                          Your company name.
                        </small>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        className={`form-group tall ${
                          message ? "filled" : ""
                        } ${ !valid['message'] ? "invalid" : ""}`}
                      >
                        <label htmlFor="message">Your Message</label>
                        <textarea
                          className="form-control"
                          type="text"
                          name="message"
                          id="message"
                          value={message}
                          onChange={this.emailInputChange}
                        />
                        <small className="form-text text-muted mb-3">
                          Type your message here.
                        </small>
                      </div>

                      <button
                        className={`btn btn-primary ${ formState === "sending" ? "clicked" : ""}`}
                        disabled={ formState !== "ready" }
                        onClick={ this.sendEmail }
                      >
                        <span className="fa-stack fa-3x">
                          <FontAwesomeIcon
                            icon={faUserAstronautPro}
                            className="fa-stack-1x"
                            color="#212b69"
                          />

                          <FontAwesomeIcon
                            icon={faUserAstronautLight}
                            className="fa-stack-1x"
                            color="#000000"
                          />
                        </span>
                        <span>Contact Us Now</span>
                        <div className="sending fa-stack fa-2x">
                          <FontAwesomeIcon
                            icon={faSpinnerThirdPro}
                            className="fa-stack-1x"
                            color="#f70000"
                            spin
                          />
                          <FontAwesomeIcon
                            icon={faSpinnerThirdPro}
                            className="fa-stack-1x"
                            color="#009300"
                            spin
                          />

                          <FontAwesomeIcon
                            icon={faSpinnerThirdPro}
                            className="fa-stack-1x"
                            color="#00001e"
                            spin
                          />
                        </div>
                      </button>
                      { messages.length > 0 ? (<div className="alert alert-default">{ messages.map(m => (<p>{m}</p>))}</div>) : null }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}
