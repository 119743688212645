import React, { Component } from "react";

import "./Footer.scss";

export default class Footer extends Component {
    render(){
      return(<div className="container-flud text-white" id="footer">
      <div className="row">
        <div className="col-12 col-lg-4"></div>
        <div className="col-12 col-lg-4"></div>
        <div className="col-12 col-lg-4"></div>
        <div className="col-12">
          &copy; agile<span className="color">collab</span> Inc. { new Date().getFullYear() }
        </div>
      </div>
    </div>)
    }
}
