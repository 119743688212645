import React, { Component } from 'react';

import "./notFound.scss";

export default class NotFound extends Component {

  render(){
    return(<div className="topNavPad">
            <h1>Not Found</h1>
          </div>);
  }
}
