import React, { Component } from "react";

//import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignRight, faTimes } from '@fortawesome/pro-regular-svg-icons'


import "./MainNav.scss";


export default class MainNav extends Component {

  constructor(props){
    super(props);

    this.state = {
      collapsed: window.innerWidth < 768 ? true : false,
      menuOpen: false
    }
  }

  componentDidMount(){
    window.addEventListener('resize', this.onResize);
  }

 onResize = () => {
   const { collapsed } = this.state;
   if( (window.innerWidth > 768 && collapsed) || (window.innerWidth < 768 && !collapsed)) {
      this.setState( { collapsed: !collapsed, menuOpen: false });
      document.body.classList.remove('noScroll');
   }
  }

  toggleMenu = (e) => {
    const { menuOpen } = this.state;
    e.preventDefault();
    this.setState({ menuOpen: !menuOpen });

    console.log(!menuOpen);
    (!menuOpen) ? document.body.classList.add('noScroll') : document.body.classList.remove('noScroll')
  }

  closeMenu = () => {
    this.setState({ menuOpen: false });
    document.body.classList.remove('noScroll');
  }

  render(){
    const { collapsed, menuOpen } = this.state;
    return(
      <nav className={`nav_wrapper ${ collapsed ? 'collapsed' : ''} ${ menuOpen ? 'menuOpen' : ''}`}>
        <button onClick={ this.toggleMenu } className="nav_item noBar toggleClosed">
              <FontAwesomeIcon icon={faTimes} size="2x" />
        </button>
        {/*
          <NavLink to="/projects" onClick={ this.closeMenu } className=" nav_item" activeClassName="active">Projects</NavLink>
          <NavLink to="/expertise" onClick={ this.closeMenu } className="nav_item" activeClassName="active">Expertise</NavLink>
          <NavLink to="/about" onClick={ this.closeMenu } className="nav_item" activeClassName="active">About</NavLink>
          <NavLink to="/carreers" onClick={ this.closeMenu } className="nav_item" activeClassName="active">Carrers</NavLink>
          */}
        <a href="/#contact" onClick={ this.closeMenu } className="nav_item contact" >Contact</a>
        <button onClick={ this.toggleMenu } className="nav_item noBar toggleOpen d-none">
              <FontAwesomeIcon icon={faAlignRight} size="2x" />
        </button>
      </nav>
    );
  }
}
